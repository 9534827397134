import { graphql } from 'gatsby'
import React,{useState, useEffect} from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import axios from 'axios';
import {Link, Trans, useTranslation} from 'gatsby-plugin-react-i18next'
import Layout from '../components/layout'
import { useMediaQuery } from 'react-responsive'
import useWindowSize from '../hooks/useGatsbyWindowSize'

import {gsap} from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { CustomEase } from "gsap/CustomEase";

gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(CustomEase);
gsap.config({autoKillThreshold: 1});



const LegalPage = (props) => {

   const { t } = useTranslation();
  var _y = 100;
  const [navStatus, setNavStatus] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [jsonContent, setJsonContent] = useState('');
  const mobile = useMediaQuery({ query: `(max-width: 767px)` });
  const {width,height} = useWindowSize();
  //const lang = props.pageContext.language == 'en' ? '/en' : '';

  //console.log( 'mobile :' + isMobile );

function toggleNave(){
  //navStatus   ? navOn(): navOff();

  navStatus ? setNavStatus(false) : setNavStatus(true);
  gsap.to(document.body,1,{delay: .2, ease: 'Quad.easeOut', scrollTo:{y: _y, autoKill: false }});

}

function loadJson(url) {

  var _json = url;

  axios.get(_json)
.then(function (response) {

  var _jsonContent =  response.data.data[0].description;
  setJsonContent(_jsonContent);
  setIsLoaded(true);


});
}



useEffect(() => {

  navStatus ? _y = 100 : _y = 0;

  setIsMobile(mobile);
  loadJson('https://stage5345.oscarjaenada.com/api/v1/legal');


  gsap.to(document.body,1,{delay: .2, ease: 'Quad.easeOut', scrollTo:{y: 80, autoKill: false }});


},[mobile,navStatus]);



if( isMobile && isLoaded ){

  var image = props.data.photos.edges[0];

return (

  <Layout>

        <div className="main-all">
         <GatsbyImage
          fluid={image.node.childImageSharp.fluid}
           alt={image.node.base.split("-").join(" ").split(".")[0]}
          image={getImage(image.node)}
        />
        </div>

        <div id="content-home">
        <div dangerouslySetInnerHTML={{ __html: jsonContent }} />
        </div>




  <nav role="navigation">
  <div id="menuToggle">
  <input
  type="checkbox"
  onClick={()=>toggleNave() }
  />

  <span></span>
  <span></span>
  <span></span>

  <ul id="menuNav" className="home">
  <li><a className="homeLink" href="/"><Trans>HOME</Trans></a></li>
  <li className="menuNavItem">
  <a className="activePage" href={"/" + props.pageContext.language + "/bio"}>BIO</a>
  </li>
  <li className="menuNavItem">
  <a href={"/" + props.pageContext.language + "/" +t('l_projects')}><Trans>PROYECTOS</Trans></a>
  </li>
  <li className="menuNavItem">
  <a href={"/" + props.pageContext.language + "/" + t('l_contact')}><Trans>CONTACTO</Trans></a>
  </li>
  <li className="menuNavItem small">
  <a className={'lang_en_'+ props.pageContext.language} href="/en">EN</a>  <a className={'lang_es_'+ props.pageContext.language} href="/es">ES</a>
  </li>

  </ul>
  </div>
  </nav>

  <div id="nav_bottom">
  <div id="nav_bottom_inner">
  <div id="logo"><a href="/">OSCAR JAENADA</a></div>


  </div>
  </div>

  </Layout>

);
}else if( !isMobile && isLoaded){

  var image = props.data.photos.edges[1];
  return (

    <Layout>

          <div className="main-all">
           <GatsbyImage
            fluid={image.node.childImageSharp.fluid}
             alt={image.node.base.split("-").join(" ").split(".")[0]}
            image={getImage(image.node)}
          />
          </div>

          <div id="content-home">
          <h1 className="center">BIO</h1>
            <div dangerouslySetInnerHTML={{ __html: jsonContent }} />

          </div>




    <nav role="navigation">
    <div id="menuToggle">
    <input
    type="checkbox"
    onClick={()=>toggleNave() }
    />

    <span></span>
    <span></span>
    <span></span>

    <ul id="menuNav" className="home">
    <li><a className="homeLink" href="/"><Trans>HOME</Trans></a></li>
    <li className="menuNavItem">
    <a className="activePage" href={"/" + props.pageContext.language + "/bio"}>BIO</a>
    </li>
    <li className="menuNavItem">
    <a href={"/" + props.pageContext.language + "/" +t('l_projects')}><Trans>PROYECTOS</Trans></a>
    </li>
    <li className="menuNavItem">
    <a href={"/" + props.pageContext.language + "/" + t('l_contact')}><Trans>CONTACTO</Trans></a>
    </li>
    <li className="menuNavItem small">
    <a className={'lang_en_'+ props.pageContext.language} href="/en">EN</a>  <a className={'lang_es_'+ props.pageContext.language} href="/es">ES</a>
    </li>

    </ul>
    </div>
    </nav>

    <div id="nav_bottom">
    <div id="nav_bottom_inner">
    <div id="logo"><a href="/">OSCAR JAENADA</a></div>


    </div>
    </div>

    </Layout>

  );
}
}


export default LegalPage


export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    file(relativePath: { eq: "index/OJ_DSC5488-1.jpg" }) {
      id
      root
      relativePath
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 400
        )
      }
    }
    photos: allFile(
          sort: { fields: base, order: ASC }
          filter: {relativeDirectory: {eq: "bio"}}
        ) {
          edges {
            node {
              id
              base
              childImageSharp {
                gatsbyImageData(
                  placeholder: DOMINANT_COLOR
                  height: 1200
                  quality: 80
                  formats: AUTO
                  transformOptions: {fit: COVER, cropFocus: ATTENTION}

                )
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }


  }
`;
